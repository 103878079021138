import { ApolloProvider } from '@apollo/client'
import '@assets/chrome-bug.css'
import '@assets/main.css'
import client from '@commerce/client'
import { ArkadeListContext } from '@components/arkade/list/context'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { pageView } from '@lib/events/pageView'
import { PrismicPreview } from '@prismicio/next'
import { LinkProps, PrismicProvider } from '@prismicio/react'
import 'keen-slider/keen-slider.min.css'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Roboto } from 'next/font/google'
import Link from 'next/link'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import { repositoryName } from 'prismicio'
import { FC, ReactNode, useEffect } from 'react'
import nextI18NextConfig from '../next-i18next.config.js'

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
})

const Noop: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    document.body.classList?.remove('loading')
    Router.events.on('routeChangeComplete', pageView)
    return () => Router.events.off('routeChangeComplete', pageView)
  }, [])

  return (
    <div className={roboto.className}>
      <PrismicProvider
        internalLinkComponent={({ href, children, ...props }: LinkProps) => (
          <Link href={href} {...props}>
            {children}
          </Link>
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <ApolloProvider client={client}>
            <Head />
            <ManagedUIContext>
              <ArkadeListContext>
                <Layout pageProps={pageProps}>
                  <Component {...pageProps} />
                </Layout>
              </ArkadeListContext>
            </ManagedUIContext>
          </ApolloProvider>
        </PrismicPreview>
      </PrismicProvider>
    </div>
  )
}
// @ts-ignore
export default appWithTranslation(MyApp, nextI18NextConfig)
