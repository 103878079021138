import { ListItemActionData } from '@components/arkade/list/context'
import { ThemeProvider } from 'next-themes'
import React, { FC, useCallback, useMemo } from 'react'

export interface State {
  displaySidebar: boolean // sidebar
  displayDropdown: boolean
  displayModal: boolean
  sidebarView: string
  modalView: string
  userAvatar: string
  cartNotificationMessage: string | null
  paintCoverageCalculated: boolean
  calculatedCoverage: number | 0
  surfaceSelectionModalContent: object | undefined
  calculatorUnit: string
  displayFilterSidebar: boolean
  upsellProducts: string[]
  upsellProductType: string
  selectedSurfaceRequiresPrepCoatUpsell: boolean
  selectedLiters: number
  addToListProduct: any | undefined
  listItemActionData: ListItemActionData | null
  selectedSurfaceTitle: string
  surfaceArea: number
  numberOfCoats: string
  interfacePC: any //holiding prismic content for drawing surface interface selection
  selectedSurfaceIndex: number
}

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  displayCartNotification: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  cartNotificationMessage: null,
  paintCoverageCalculated: false,
  calculatedCoverage: 0,
  surfaceSelectionModalContent: undefined,
  calculatorUnit: 'metres',
  displayFilterSidebar: false,
  upsellProducts: [],
  upsellProductType: '',
  selectedSurfaceRequiresPrepCoatUpsell: false,
  selectedLiters: 0,
  addToListProduct: undefined,
  listItemActionData: null,
  selectedSurfaceTitle: '',
  surfaceArea: 0,
  numberOfCoats: 'two',
  interfacePC: undefined,
  selectedSurfaceIndex: -1,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'OPEN_CART_NOTIFICATION'
      cartNotificationMessage: string
    }
  | {
      type: 'CLOSE_CART_NOTIFICATION'
    }
  | {
      type: 'START_PAINT_COVERAGE_CALCULATION'
      coverage: number
    }
  | {
      type: 'END_PAINT_COVERAGE_CALCULATION'
      coverage: number
    }
  | {
      type: 'SET_SURFACE_SELECTION_MODAL_CONTENT'
      surfaceSelectionModalContent: object
    }
  | {
      type: 'SET_CALCULATOR_UNIT'
      calculatorUnit: CALCULATOR_UNITS
    }
  | {
      type: 'OPEN_FILTER_SIDEBAR'
    }
  | {
      type: 'CLOSE_FILTER_SIDEBAR'
    }
  | {
      type: 'SET_UPSELL_VIEW_CONTENT'
      cartNotificationMessage: string
      upsellProducts: string[]
    }
  | {
      type: 'SET_UPSELL_PRODUCT_TYPE'
      upsellProductType: string
    }
  | {
      type: 'SET_SELECTED_SURFACE_REQUIRES_PREP_COAT_UPSELL'
      selectedSurfaceRequiresPrepCoatUpsell: boolean
    }
  | {
      type: 'SET_SELECTED_LITERS'
      selectedLiters: number
    }
  | {
      type: 'SET_ADD_TO_LIST_PRODUCT'
      addToListProduct: any
    }
  | {
      type: 'SET_LIST_ITEM_ACTION_DATA'
      listItemActionData: ListItemActionData | null
    }
  | {
      type: 'SET_SELECTED_SURFACE_TITLE'
      value: string
    }
  | {
      type: 'SET_SURFACE_AREA'
      value: number
    }
  | {
      type: 'SET_NUMBER_OF_COATS'
      value: string
    }
  | {
      type: 'SET_INTERFACE_PC'
      value: any
    }
  | {
      type: 'SET_SELECTED_SURFACE_INDEX'
      value: number
    }

type CALCULATOR_UNITS = 'metres' | 'feet'
type MODAL_VIEWS =
  // | 'SIGNUP_VIEW'
  // | 'LOGIN_VIEW'
  // | 'FORGOT_VIEW'
  // | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'
  | 'COUNTRY_SELECTOR_VIEW'
  | 'SURFACE_DETAIL_MODAL_VIEW'
  | 'PAINT_CALCULATOR_MODAL_VIEW'
  | 'SURFACE_SELECTION_MODAL_VIEW'
  | 'PAINT_SELECTION_MODAL_VIEW'

type SIDEBAR_VIEWS =
  | 'CART_VIEW'
  | 'SEARCH_VIEW'
  // | 'CHECKOUT_VIEW'
  // | 'PAYMENT_METHOD_VIEW'
  | 'COUNTRY_SELECTOR_VIEW'
  | 'SURFACE_DETAIL_MODAL_VIEW'
  | 'PAINT_CALCULATOR_MODAL_VIEW'
  | 'SURFACE_SELECTION_MODAL_VIEW'
  | 'MOBILE_PROFILE_SIDEBAR_VIEW'
  | 'PAINT_SELECTION_MODAL_VIEW'
  | 'GARDEN_POT_MODAL_VIEW'
  | 'WISH_LIST_SIDEBAR_VIEW'
  | 'SAVE_WISH_LIST_SIDEBAR_VIEW'
  | 'SHARE_WISH_LIST_SIDEBAR_VIEW'
  | 'SHARE_WISH_LIST_SIDEBAR_MOBILE_VIEW'
  | 'SAVE_WISH_LIST_SIDEBAR_MOBILE_VIEW'
  | 'SHARE_ARTICLE_SIDEBAR_VIEW'
  | 'SHARE_ARTICLE_SIDEBAR_MOBILE_VIEW'
  | 'UPSELL_VIEW'
  | 'CREATE_NEW_LIST_SIDEBAR_VIEW'
  | 'CREATE_NEW_LIST_ONLY_SIDEBAR_VIEW'
  | 'POST_CREATE_NEW_LIST_SIDEBAR_VIEW'
  | 'ADD_TO_LIST_SIDEBAR_VIEW'
  | 'EDIT_WISH_LIST_NAME_SIDEBAR_VIEW'
  | 'EDIT_WISH_LIST_NAME_SIDEBAR_MOBILE_VIEW'
  | 'DELETE_WISH_LIST_SIDEBAR_VIEW'
  | 'DELETE_WISH_LIST_SIDEBAR_MOBILE_VIEW'
  | 'MOVE_LIST_ITEM_SIDEBAR_VIEW'
  | 'MOVE_LIST_ITEM_SIDEBAR_MOBILE_VIEW'
  | 'DUPLICATE_LIST_ITEM_SIDEBAR_VIEW'
  | 'DUPLICATE_LIST_ITEM_SIDEBAR_MOBILE_VIEW'
  | 'LIST_ITEM_ACTIONS_MOBILE_SIDEBAR_VIEW'
  | 'LIST_ACTIONS_SIDEBAR_VIEW'
  | 'RIGHT_SAMPLE_SIDEBAR_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarIsClosing: false,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'OPEN_CART_NOTIFICATION': {
      return {
        ...state,
        displayCartNotification: true,
        cartNotificationMessage: action.cartNotificationMessage,
      }
    }
    case 'CLOSE_CART_NOTIFICATION': {
      return {
        ...state,
        displayCartNotification: false,
        cartNotificationMessage: null,
      }
    }
    case 'START_PAINT_COVERAGE_CALCULATION': {
      return {
        ...state,
        paintCoverageCalculated: false,
        calculatedCoverage: action.coverage,
      }
    }
    case 'END_PAINT_COVERAGE_CALCULATION': {
      return {
        ...state,
        paintCoverageCalculated: true,
        calculatedCoverage: action.coverage,
      }
    }
    case 'SET_SURFACE_SELECTION_MODAL_CONTENT': {
      return {
        ...state,
        surfaceSelectionModalContent: action.surfaceSelectionModalContent,
      }
    }
    case 'SET_CALCULATOR_UNIT': {
      return {
        ...state,
        calculatorUnit: action.calculatorUnit,
        paintCoverageCalculated: false,
      }
    }
    case 'OPEN_FILTER_SIDEBAR': {
      return {
        ...state,
        displayFilterSidebar: true,
      }
    }
    case 'CLOSE_FILTER_SIDEBAR': {
      return {
        ...state,
        displayFilterSidebar: false,
      }
    }
    case 'SET_UPSELL_VIEW_CONTENT': {
      return {
        ...state,
        cartNotificationMessage: action.cartNotificationMessage,
        upsellProducts: action.upsellProducts,
      }
    }
    case 'SET_UPSELL_PRODUCT_TYPE': {
      return {
        ...state,
        upsellProductType: action.upsellProductType,
      }
    }
    case 'SET_SELECTED_SURFACE_REQUIRES_PREP_COAT_UPSELL': {
      return {
        ...state,
        selectedSurfaceRequiresPrepCoatUpsell:
          action.selectedSurfaceRequiresPrepCoatUpsell,
      }
    }
    case 'SET_SELECTED_LITERS': {
      return {
        ...state,
        selectedLiters: action.selectedLiters,
      }
    }
    case 'SET_ADD_TO_LIST_PRODUCT': {
      return {
        ...state,
        addToListProduct: action.addToListProduct,
      }
    }
    case 'SET_LIST_ITEM_ACTION_DATA': {
      return {
        ...state,
        listItemActionData: action.listItemActionData,
      }
    }
    case 'SET_SELECTED_SURFACE_TITLE': {
      return {
        ...state,
        selectedSurfaceTitle: action.value,
      }
    }
    case 'SET_SURFACE_AREA': {
      return {
        ...state,
        surfaceArea: action.value,
      }
    }
    case 'SET_NUMBER_OF_COATS': {
      return {
        ...state,
        numberOfCoats: action.value,
      }
    }
    case 'SET_INTERFACE_PC': {
      return {
        ...state,
        interfacePC: action.value,
      }
    }
    case 'SET_SELECTED_SURFACE_INDEX': {
      return {
        ...state,
        selectedSurfaceIndex: action.value,
      }
    }
  }
}

export const UIProvider: FC<any> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)
  const openSidebar = useCallback(() => {
    if (state.displaySidebar) {
      dispatch({ type: 'CLOSE_SIDEBAR' })
      setTimeout(() => {
        dispatch({ type: 'OPEN_SIDEBAR' })
      }, 450)
    } else {
      dispatch({ type: 'OPEN_SIDEBAR' })
    }
  }, [dispatch, state.displaySidebar])
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const openCartNotification = useCallback(
    (cartNotificationMessage: any) => {
      dispatch({ type: 'OPEN_CART_NOTIFICATION', cartNotificationMessage })
      setTimeout(() => {
        dispatch({ type: 'CLOSE_CART_NOTIFICATION' })
      }, 3000)
    },
    [dispatch]
  )
  const closeCartNotification = useCallback(
    () => dispatch({ type: 'CLOSE_CART_NOTIFICATION' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )
  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )
  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )
  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )
  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )
  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )
  const openCalculator = useCallback(
    (coverage: number) =>
      dispatch({ type: 'START_PAINT_COVERAGE_CALCULATION', coverage }),
    [dispatch]
  )
  const closeCalculator = useCallback(
    (coverage: number) =>
      dispatch({ type: 'END_PAINT_COVERAGE_CALCULATION', coverage }),
    [dispatch]
  )
  const setSurfaceSelectionModalContent = useCallback(
    (surfaceSelectionModalContent: object) =>
      dispatch({
        type: 'SET_SURFACE_SELECTION_MODAL_CONTENT',
        surfaceSelectionModalContent,
      }),
    [dispatch]
  )
  const setCalculatorUnit = useCallback(
    (calculatorUnit: CALCULATOR_UNITS) =>
      dispatch({
        type: 'SET_CALCULATOR_UNIT',
        calculatorUnit,
      }),
    [dispatch]
  )
  const openFilterSidebar = useCallback(
    () => dispatch({ type: 'OPEN_FILTER_SIDEBAR' }),
    [dispatch]
  )
  const closeFilterSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_FILTER_SIDEBAR' }),
    [dispatch]
  )
  const setUpsellViewContent = useCallback(
    (cartNotificationMessage: string, upsellProducts: string[]) =>
      dispatch({
        type: 'SET_UPSELL_VIEW_CONTENT',
        cartNotificationMessage,
        upsellProducts,
      }),
    [dispatch]
  )
  const setUpsellProductType = useCallback(
    (upsellProductType: string) =>
      dispatch({ type: 'SET_UPSELL_PRODUCT_TYPE', upsellProductType }),
    [dispatch]
  )
  const setSelectedSurfaceRequiresPrepCoatUpsell = useCallback(
    (selectedSurfaceRequiresPrepCoatUpsell: boolean) =>
      dispatch({
        type: 'SET_SELECTED_SURFACE_REQUIRES_PREP_COAT_UPSELL',
        selectedSurfaceRequiresPrepCoatUpsell,
      }),
    [dispatch]
  )
  const setSelectedLiters = useCallback(
    (selectedLiters: number) =>
      dispatch({ type: 'SET_SELECTED_LITERS', selectedLiters }),
    [dispatch]
  )
  const setAddToListProduct = useCallback(
    (addToListProduct: any) =>
      dispatch({ type: 'SET_ADD_TO_LIST_PRODUCT', addToListProduct }),
    [dispatch]
  )
  const setListItemActionData = useCallback(
    (listItemActionData: ListItemActionData | null) =>
      dispatch({ type: 'SET_LIST_ITEM_ACTION_DATA', listItemActionData }),
    [dispatch]
  )

  const setSelectedSurfaceTitle = useCallback(
    (selectedSurfaceTitle: string) =>
      dispatch({
        type: 'SET_SELECTED_SURFACE_TITLE',
        value: selectedSurfaceTitle,
      }),
    [dispatch]
  )

  const setSurfaceArea = useCallback(
    (surfaceArea: number) =>
      dispatch({
        type: 'SET_SURFACE_AREA',
        value: surfaceArea,
      }),
    [dispatch]
  )

  const setNumberOfCoats = useCallback(
    (numberOfCoats: string) =>
      dispatch({
        type: 'SET_NUMBER_OF_COATS',
        value: numberOfCoats,
      }),
    [dispatch]
  )

  const setInterfacePC = useCallback(
    (interfacePC: any) =>
      dispatch({
        type: 'SET_INTERFACE_PC',
        value: interfacePC,
      }),
    [dispatch]
  )

  const setSelectedSurfaceIndex = useCallback(
    (selectedSurfaceIndex: number) =>
      dispatch({
        type: 'SET_SELECTED_SURFACE_INDEX',
        value: selectedSurfaceIndex,
      }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
      openCartNotification,
      closeCartNotification,
      openCalculator,
      closeCalculator,
      setSurfaceSelectionModalContent,
      setCalculatorUnit,
      openFilterSidebar,
      closeFilterSidebar,
      setUpsellViewContent,
      setUpsellProductType,
      setSelectedSurfaceRequiresPrepCoatUpsell,
      setSelectedLiters,
      setAddToListProduct,
      setListItemActionData,
      setSelectedSurfaceTitle,
      setSurfaceArea,
      setNumberOfCoats,
      setInterfacePC,
      setSelectedSurfaceIndex,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}
interface Props {
  children: React.ReactNode
}
export const ManagedUIContext: FC<Props> = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
