import commerceConfig from '@commerce/config'
import { getConfigByLocale } from '@lib/locale'
import { useCustomerPrivacy, VisitorConsentCollected } from '@shopify/hydrogen'
import Script from 'next/script'
import { useEffect, useState } from 'react'

//    Shopify consent
//     marketing: true,
//     analytics: true,
//     preferences: true,
//     sale_of_data: true,

declare global {
  interface Window {
    gtag: (...args: any[]) => void
  }
}

const GTM_CONTAINER_WITH_CONSET = () => {
  const [gtmLoaded, setGtmLoaded] = useState(false)
  const [consentLoaded, setConsentLoaded] = useState<
    boolean | VisitorConsentCollected
  >(false)
  const {
    config: { defaultLocale },
  } = commerceConfig
  const storefrontAccessToken = getConfigByLocale(
    defaultLocale,
    'shopifyAPIToken'
  )

  const handleConsentLoaded = (consent: any) => setConsentLoaded(consent)

  const shopifyDomain = getConfigByLocale(defaultLocale, 'shopifyDomain')
  const { customerPrivacy } = useCustomerPrivacy({
    storefrontAccessToken,
    checkoutDomain: shopifyDomain,
    onVisitorConsentCollected: (consent) => {
      handleConsentLoaded(consent)
    },
  })

  const defaultConsent = {
    analytics_storage: 'denied',
    ad_storage: 'denied',
    personalization_storage: 'denied',
  }

  const CONSET_SETUP = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('consent', 'default', ${JSON.stringify(defaultConsent)});
  `

  const handleGtmLoaded = () => {
    setGtmLoaded(true)
  }

  useEffect(() => {
    if (gtmLoaded && consentLoaded) {
      const acceptedConsent = {
        analytics_storage:
          typeof consentLoaded !== 'boolean' && consentLoaded.analyticsAllowed
            ? 'granted'
            : 'denied',
        ad_storage:
          typeof consentLoaded !== 'boolean' && consentLoaded.analyticsAllowed
            ? 'granted'
            : 'denied',
        personalization_storage:
          typeof consentLoaded !== 'boolean' && consentLoaded.preferencesAllowed
            ? 'granted'
            : 'denied',
      }
      window.gtag('consent', 'update', acceptedConsent)
      console.log('acceptedConsent', acceptedConsent)
    }
  }, [gtmLoaded, consentLoaded])

  return (
    <Script
      id="gtm-script"
      dangerouslySetInnerHTML={{
        __html: `${CONSET_SETUP}(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
      }}
      strategy="afterInteractive"
      onReady={handleGtmLoaded}
    />
  )
}

export default process.env.NEXT_PUBLIC_GTM_ID ? (
  <GTM_CONTAINER_WITH_CONSET />
) : (
  ''
)
